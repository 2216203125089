<template>
    <v-calendar v-if="(filtro.id_franquia && filtro.id_turma) || usuarioSessao.id_grupo == 4" :weekdays="[0, 1, 2, 3, 4, 5, 6]" ref="calendar" v-model="dataCalendario" type="month" :events="aulas" @click:event="toAula" color="primary" event-overlap-mode="stack" />
</template>

<script>

import axios from "axios"
import {mapState} from "vuex"

export default {
    name: "AulaListar",
    props : ['filtro'],
    components: {},
    data() {
        return {
            hj : null,
            dataCalendario : null,
            aulas : {
                lista : [],
                total : 0
            }
        }
    },
    computed : {
        ...mapState(['apiUrl', 'paginationLimit', 'usuarioSessao'])
    },
    methods : {
        paginar() {
            if((this.filtro.id_franquia && this.filtro.id_turma) || this.usuarioSessao.id_grupo == 4) {
                let data = this.dataCalendario.split('-')
                const ultimoDiaMes = new Date(data[0], data[1], 0)
                return axios.post(`${this.apiUrl}aula/listar`, {
                    busca       : this.filtro.busca || null,
                    id_turma    : this.filtro.id_turma || null,
                    dt_inicio   : data[0] + '-' + data[1] + '-01',
                    dt_fim      : ultimoDiaMes.getFullYear() + '-' + (ultimoDiaMes.getMonth() + 1 < 10 ? '0' : '') + (ultimoDiaMes.getMonth() + 1) + '-' + ultimoDiaMes.getDate(),
                    id_franquia : this.filtro.id_franquia || null,
                }).then( (res) => {
                    this.aulas = res.data.lista.map((a) => {
                        return {
                            id_aula : a.id_aula,
                            name    : a.turma,
                            start   : a.dh_inicio,
                            end     : a.dh_fim
                        }
                    })
                })
            }
        },
        anterior() {
            this.$refs.calendar.prev()
        },
        proximo() {
            this.$refs.calendar.next()
        },
        toAula(a) {
            this.$router.push(`/aula/gerenciar/${a.event.id_aula}`)
        },
        init() {
            this.hj = new Date()
            this.dataCalendario = `${this.hj.getFullYear()}-${this.hj.getMonth() + 1 < 10 ? '0' : ''}${this.hj.getMonth() + 1}-01`
            this.paginar()
        }
    },
    watch : {
        'filtro.id_franquia' : function () {
            this.paginar()
        },
        'filtro.id_turma' : function () {
            this.paginar()
        },
        'filtro.paginar' : function () {
            this.paginar()
        },
        'dataCalendario' : function (v) {
            this.$emit('dataCalendario', v)
            this.paginar()
        }
    },
    activated() {
        this.init()
    },
    created() {
        this.init()
    }
}
</script>

<style scoped>

</style>
